import React, { useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useSpring, animated } from 'react-spring'

import "../../styles/inline/themes.scss"
import styles from "./style.module.scss"

const FormBlock = ( props ) => {
	
	return (
		
		<section className={ styles.block }>
			<animated.div className={ `${ styles.overlay } background--${ props.bgColour }`}>
				<div className={ styles.content }>
					<h1>Contact us</h1>
					<p>
						Painesend Farm, Tring<br/>
						Hertfordshire, HP23 6JU<br/>
						<br/>
						<a href="mailto:admin@painesend.com" target="_blank">admin@painesend.com</a>
					</p>
					<form
						className={ styles.form }
						name="Enquiry"
						method="post"
						action={props.thanks}
						data-netlify="true"
						data-netlify-honeypot="bot-field">
						<input type="hidden" name="form-name" value="Enquiry" />
						<p hidden>
							<label>Don’t fill this out: <input name="bot-field"/></label>
						</p>
						
						<div className={ styles.field }>
							<label>
								<p>Full name*</p>
								<input type="text" name="name" required/>
							</label>
						</div>

						<div className={ styles.field }>
							<label>
								<p>Email address*</p>
								<input type="email" name="email" required/>
							</label>
						</div>

						<div className={ styles.field }>
							<label>
								<p>Message*</p>
								<textarea name="message" required></textarea>
							</label>
						</div>
						
						<p><small>*Mandatory field</small></p>
							
						<button className={ styles.button } type="submit">Send</button>
					</form>
					
				</div>
			</animated.div>
		</section>
		
	)
}

export default FormBlock

